<template>
  <div>
    <b-container class="p-4 corpo" style="margin-top: 10em;" id="nossos">
      <h2
        class="text-center"
        style="color:#330667;font-weight: bold;margin-top:3em;"
      >
        NOSSOS SERVIÇOS
      </h2>

      <br />
    </b-container>
    <b-container style="margin-top:6em" class="mb-5 py-3">
      <div id="degrada" v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row class="p-4">
          <b-col cols="9">
            <h2 style="color:#330667;font-weight:bold">
              QUALIFICAÇÃO DE IMPUREZAS E PRODUTOS DE DEGRADAÇÃO:
            </h2>
            <br />Avaliação toxicológica com base nas premissas dos guias ICH
            M7, Q3A, Q3B e ANVISA RDC 53/2015 para garantir a segurança de
            impurezas presentes em insumos farmacêuticos ativos (IFAs) e/ou em
            medicamentos, acima de limites considerados seguros.
          </b-col>
        </b-row>
        <b-row class="p-4">
          <b-col cols="9">
            <b>
              Predição
              <i>in silico</i> de produtos de degradação:
            </b>
            Através do software Zeneth® (Lhasa Limited® - Leeds, Reino Unido)
            são realizadas simulações para avaliação do perfil de degradação do
            insumo farmacêutico ativo frente a condições de estresse como:
            calor, umidade, luz, meios ácidos, básicos e oxidativos. Através
            deste estudo é possível identificar os mecanismos de degradação do
            IFA e os produtos de degradação formados.
            <br />
            <br />
            <b>
              Predição
              <i>in silico</i> da compatibilidade entre ativo-excipiente:
            </b>
            Avaliação das possíveis interações entre os ativos e excipientes
            levando em consideração as principais impurezas contaminantes dos
            excipientes através do software Zeneth® (Lhasa Limited® - Leeds,
            Reino Unido).
          </b-col>
        </b-row>
      </div>
      <hr
        id="nitro"
        class="meio"
        style="width:40%;margin-top: 6em;margin-bottom:6em;height: 0.1em;background-color:white;"
      />
      <div v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row class="p-4">
          <b-col style="text-align:left;">
            <h2 style="color:#330667;font-weight:bold">
              AVALIAÇÃO DO RISCO DE IMPUREZAS MUTAGÊNICAS (Nitrosaminas) – ICH
              M7:
            </h2>
            <br />Avaliação teórica de formação das impurezas (nitrosaminas) e
            de seu potencial mutagênico através das informações obtidas da rota
            sintética completas dos insumos farmacêuticos ativos (IFAs) e também
            dos produtos de degradação do produto. A avaliação do potencial
            mutagênico será realizada através das ferramentas in silico Derek e
            Sarah Nexus® (Lhasa Limited® - Leeds, Reino Unido). A possibilidade
            de seu controle através do fator de purga (opção 4 do guia ICH M7)
            será realizado através do software Mirabilis® (Lhasa Limited® -
            Leeds, Reino Unido).
          </b-col>
        </b-row>
        <b-row class="p-4">
          <b-col style="text-align:left;">
            <b>Determinação do perfil de impurezas do IFA:</b> Avaliação,
            identificação e discriminação de impurezas de degradação ou de rotas
            de síntese com proposta teórica de formação das impurezas.
          </b-col>
        </b-row>
      </div>
      <hr
        id="lixi"
        class="meio"
        style="width:40%;margin-top: 6em;margin-bottom:6em;height: 0.1em;background-color:white;"
      />
      <div v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row class="p-4">
          <b-col style="text-align:left;" cols="9">
            <h2 style="color:#330667;font-weight:bold">
              AVALIAÇÃO DO RISCO DE EXTRAÍVEIS E LIXIÁVEIS E DEFINIÇÃO DE
              LIMITES SEGUROS PARA LIXIÁVEIS:
            </h2>
            <br />Identificação da presença em potencial de lixiviáveis no
            produto final, levando em consideração as informações de estudos de
            extraíveis das embalagens primárias e as propriedades
            físico-químicas do produto. Definição de limites seguros para o
            controle de lixiviáveis.
          </b-col>
        </b-row>
      </div>
      <hr
        id="element"
        class="meio"
        style="width:40%;margin-top: 6em;margin-bottom:6em;height: 0.1em;background-color:white;"
      />
      <div v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row class="p-4">
          <b-col style="text-align:left;">
            <h2 style="color:#330667;font-weight:bold">
              AVALIAÇÃO DO RISCO DA PRESENÇA DE IMPUREZAS ELEMENTARES:
            </h2>
            <br />Organização de informações do processo de produção dos
            medicamentos e identificação da presença em potencial de impurezas
            elementares em cada uma de suas etapas à fim de estabelecer o risco
            da presença destas impurezas no produto final.
          </b-col>
        </b-row>
      </div>
      <hr
        id="pred"
        class="meio"
        style="width:40%;margin-top: 6em;margin-bottom:6em;height: 0.1em;background-color:white;"
      />
      <div v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row class="p-4">
          <b-col style="text-align:left;" cols="9">
            <h2 style="color:#330667;font-weight:bold">
              PREDIÇÃO
              <i>IN SILICO</i>
              <br />DE PRODUTOS DE DEGRADAÇÃO:
            </h2>
            <br />Com o software Zeneth® (Lhasa Limited® - Leeds, Reino Unido),
            são realizadas simulações para avaliar o perfil de degradação do
            insumo farmacêutico ativo frente a condições de estresse como,
            calor, umidade, luz, meios ácidos, básicos e oxidativos. Através
            deste estudo é possível identificar os mecanismos de degradação do
            IFA e os produtos de degradação formados.
          </b-col>
        </b-row>
      </div>
      <hr
        id="PDE"
        class="meio"
        style="width:40%;margin-top: 6em;margin-bottom:6em;height: 0.1em;background-color:white;"
      />
      <div v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row class="p-4">
          <b-col style="text-align:left;" cols="9">
            <h2 style="color:#330667;font-weight:bold">
              DETERMINAÇÃO DE LIMITES SEGUROS COM BASE EM SAÚDE
              <br />(PDE – Permitted Daily Exposure):
            </h2>
            <br />Definição dos PDE dos insumos farmacêuticos ativos para o
            controle de contaminação cruzada no compartilhamento de áreas
            produtivas em atendimento à RDC 301/2019.
          </b-col>
        </b-row>
      </div>
      <hr
        id="vet"
        class="meio"
        style="width:40%;margin-top: 6em;margin-bottom:6em;height: 0.1em;background-color:white;"
      />
      <div v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row class="p-4">
          <b-col style="text-align:left;">
            <h2 style="color:#330667;font-weight:bold">
              AVALIAÇÃO DO RISCO TOXICOLÓGICO DE MEDICAMENTOS VETERINÁRIOS PARA
              DEFINIÇÃO DE LMR (Limite Máximo de Resíduo) E CUMPRIMENTO DA
              REGULAMENTAÇÃO RDC Nº 328/2019:
            </h2>
            <br />Avaliação do risco à saúde humana de medicamentos veterinários
            e os métodos de análise para fins de avaliação da conformidade.
          </b-col>
        </b-row>
      </div>
      <hr
        id="sint"
        class="meio"
        style="width:40%;margin-top: 6em;margin-bottom:6em;height: 0.1em;background-color:white;"
      />
      <div v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row class="p-4">
          <b-col style="text-align:left;">
            <h2 style="color:#330667;font-weight:bold">
              SOLUCÕES CIENTÍFICAS EM QUÍMICA MEDICINAL e SÍNTESE ORGÂNICA:
            </h2>
            <br />fornecemos orientação, avaliação e diretrizes para o
            desenvolvimento de projetos com foco em inovação científica nas
            áreas de descoberta e desenvolvimento de novas moléculas bioativas,
            extratos fitoterápicos.
          </b-col>
        </b-row>
        <hr
          id="ment"
          class="meio"
          style="width:40%;margin-top: 6em;margin-bottom:6em;height: 0.1em;background-color:white;"
        />
        <div v-animate-onscroll.repeat="{ down: 'animated pulse' }">
          <b-row class="p-4">
            <h2 style="color:#330667;font-weight:bold">CURSOS E MENTORIA</h2>
            A Spektra fornece treinamentos personalizados sobre qualquer tema da
            nossa área de atuação, ou seja, nas áreas de desenvolvimento de
            métodos indicativos de estabilidade, estudos de degradação forçada,
            balanço de massas, avaliação da toxicidade de impurezas, ferramentas
            in silico, estudos de compatibilidade, desenvolvimento de métodos e
            produtos através das ferramentas Fusion QbD® e ASAPprime®.
            <br />Além disso, caso você esteja interessado em fazer uma
            transição de carreira e se identificou com a nossa história e nossas
            experiências, não hesite em nos contatar. Teremos o imenso prazer de
            te ajudar neste processo.
          </b-row>
        </div>
      </div>
    </b-container>
    <b-container class="mb-5">
      <div v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
        <b-row style="justify-content: center;">
          <img
            class="imgInicial"
            src="@/assets/Site_20.jpg"
            alt="Sistemas"
            style="        width: 70%;
                                             "
          />
        </b-row>
      </div>
      <b-container class="p-4 mt-3">
        <h2
          class="text-center"
          style="color:#330667;font-weight: bold;margin-top:3em;"
        >
          FERRAMENTAS
        </h2>

        <br />
      </b-container>
      <div
        v-animate-onscroll.repeat="{ down: 'animated pulse' }"
        style="margin-top:6em"
      >
        <b-row class="p-4" id="ASAP">
          <h4 style="color:#330667;font-weight:bold">
            ASAPprime® (FreeThink Technologies)
          </h4>
          Comercialização, assistência técnica, desenvolvimento de projetos e
          treinamento do software ASAPprime®. Software para predição de
          estabilidade dos produtos em apenas 1 mês, indicando de maneira
          assertiva qual a melhor embalagem para o seu produto. Além disso,
          quando utilizado na etapa de desenvolvimento de formulação, auxilia
          nos estudos de seleção de excipientes, substituindo os estudos
          convencionais de compatibilidade, e também promove melhoria de
          processo, com foco em garantir produtos estáveis e com prazos de
          validade mais longos. Também oferecemos o serviço de desenvolvimento
          de formulações através da FreeThink Technologies (Connecticut – EUA).
        </b-row>
      </div>
      <div v-animate-onscroll.repeat="{ down: 'animated pulse' }">
        <b-row class="p-4">
          <h4 style="color:#330667;font-weight:bold">
            Fusion QbD® (S-matrix®)
          </h4>
          Comercialização, assistência técnica e treinamento do pacote Fusion
          QbD®. A utilização deste software no desenvolvimento de métodos
          promove aumento de produtividade e assertividade, pois em apenas 15
          dias é possível avaliar uma grande quantidade de parâmetros
          relacionado ao desenvolvimento do seu método (fases móveis e
          estacionárias), assim como a sua robustez. Todas as aplicações são
          realizadas de forma automatizada e através do conceito de Quality by
          Design.
        </b-row>
      </div>
    </b-container>
    <br />
    <br />
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
export default {
  name: "Atuacao",
  components: {
    Footer,
  },
  mounted: function() {
    this.customroute = this.$router.currentRoute.fullPath;
    if (this.customroute === "/AtuacaoQuali") {
      this.$vuetify.goTo("#Quali");
    }
    if (this.customroute === "/AtuacaoImpuEle") {
      this.$vuetify.goTo("#ImpuEle");
    }
    if (this.customroute === "/AtuacaoLixi") {
      this.$vuetify.goTo("#Lixi");
    }
    if (this.customroute === "/AtuacaoNitro") {
      this.$vuetify.goTo("#Nitro");
    }
    if (this.customroute === "/AtuacaoPred") {
      this.$vuetify.goTo("#Pred");
    }
    if (this.customroute === "/AtuacaoExcip") {
      this.$vuetify.goTo("#Excip");
    }
    if (this.customroute === "/AtuacaoPDE") {
      this.$vuetify.goTo("#PDE");
    }
    if (this.customroute === "/AtuacaoCompat") {
      this.$vuetify.goTo("#Compat");
    }
    if (this.customroute === "/AtuacaoASAP") {
      this.$vuetify.goTo("#ASAP");
    }
    if (this.customroute === "/AtuacaoTrein") {
      this.$vuetify.goTo("#Trein");
    }
  },
};
</script>
<style scoped>
p {
  vertical-align: top;
  text-indent: 2em;
}
#logo1 {
  opacity: 0.4;
  width: 70%;
}
i {
  font-size: small;
}
#verde {
  color: #98d02f;
}

@media only screen and (max-width: 768px) {
  .imgInicial {
    width: 95% !important;
  }
  hr {
    width: 100% !important;
    margin-top: 1em !important;
    margin-bottom: 0em !important;
    height: 2px !important;
    background-color: rgb(152, 208, 47) !important;
  }
  .meio {
    background-color: rgb(236, 233, 233) !important;
    height: 1px !important;
  }
  .corpo {
    margin-top: -1em !important;
  }
}
</style>
